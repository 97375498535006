import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityDataProvider } from '@entity-framework/entity-record/entity-data-provider/abstract-entity.data-provider';
import { environment } from '@env/environment';
import { LoggingService } from '@logging/logging.service';
import { ActiveUser } from './active-user';

/**
 * Provides http access to the currently signed-in user's security context (organisations and roles)
 */
@Injectable({ providedIn: 'root' })
export class ActiveUserDataProvider extends AbstractEntityDataProvider<ActiveUser> {
  protected apiRoot = `${environment.apiPaths.activeSecurity}/active-user`;

  constructor(
    protected httpClient: HttpClient,
    protected logger: LoggingService
  ) {
    super(httpClient, logger);
  }
}
